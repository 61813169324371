const EventService = {
  on(event: string, callback: (...args: any) => void) {
    document.addEventListener(event, (e) => callback((<CustomEvent>e).detail));
  },
  dispatch(event: string, data?: any) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event: string, callback?: () => void) {
    document.removeEventListener(event, () => callback?.());
  },
};

export default EventService;
