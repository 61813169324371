import React, { useEffect } from "react";

import clsx from "clsx";

import namespaced from "~/src/utils/debug";

const debug = namespaced("components/modals/ModalWrapper");

type ModalWrapperProps = { children: React.ReactNode };

function ModalWrapper({ children }: ModalWrapperProps) {
  /**
   * Prevent scrolling behind modal
   */
  useEffect(() => {
    debug("will add overflow-hidden to body");
    document.body.classList.add("overflow-hidden", "from-modalwrapper");
    return () => {
      if (document.body.classList.contains("from-modalwrapper")) {
        debug("will remove overflow-hidden to body");
        document.body.classList.remove("overflow-hidden", "from-modalwrapper");
      }
    };
  }, []);

  return (
    <React.Fragment>
      <div
        className={clsx("z-50", "h-screen w-screen max-w-full", "fixed top-0 left-0", "bg-[#191A18]", "opacity-50")}
      />
      <div className={clsx("z-50", "h-screen w-screen max-w-full", "fixed top-0 left-0")}>
        <div
          className={clsx(
            "w-full",
            "h-full",
            "py-6",
            "px-3 xs:px-5",
            "flex items-center justify-center",
            "flex-col md:flex-row",
          )}
        >
          {children}
        </div>
      </div>
    </React.Fragment>
  );
}

export default React.memo(ModalWrapper);
