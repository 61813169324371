import client from "~/src/api-client";
import {
  CreateUserAddress,
  CreateUserAddressPayload,
  GetUserAddresses,
  GetUserAddressesPayload,
  ParsedAddress,
  UpdateAddress,
  UpdateAddressPayload,
} from "~/src/types/addresses";
import { parseAddressFromBackend } from "~/src/utils/address";
import namespaced from "~/src/utils/debug";

const debug = namespaced("api-client/addresses");

export async function createUserAddress({
  data,
  token,
}: CreateUserAddress): Promise<CreateUserAddressPayload["data"] | undefined> {
  try {
    const res = await client.post<CreateUserAddressPayload>("/addresses/create_v2/", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    debug(`[createUserAddress] response was ${res.status}`, res);
    return res.data.data;
  } catch (err) {
    debug("[createUserAddress]", err);

    return undefined;
  }
}

export async function deleteUserAddress(addressId: string): Promise<boolean | undefined> {
  try {
    const res = await client.delete<string>(`/addresses/${addressId}/`);
    debug(`[deleteUserAddress] response was ${res.status}`, res);
    return true;
  } catch (err) {
    debug("[deleteUserAddress]", err);

    return undefined;
  }
}

export async function getUserAddresses(token: GetUserAddresses): Promise<ParsedAddress[] | undefined> {
  try {
    const res = await client.get<GetUserAddressesPayload>(`/addresses/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    debug(`[getUserAddresses] response was ${res.status}`, res);
    if (res && !!res.data?.data) {
      debug("[getUserAddresses] will parse addresses", res.data.data);
      const parsed = res.data.data.map(parseAddressFromBackend).filter((pa) => !!pa) as ParsedAddress[];
      debug("[getUserAddresses] success parsing addresses", parsed);
      return parsed;
    } else {
      debug("[getUserAddresses] failed to get user addresses");

      return undefined;
    }
  } catch (err) {
    debug("[getUserAddresses]", err);

    return undefined;
  }
}

export async function updateAddress({ addressId, data }: UpdateAddress): Promise<UpdateAddressPayload | undefined> {
  try {
    const res = await client.patch<UpdateAddressPayload>(`/addresses/${addressId}/`, data);
    debug(`[updateAddress] response was ${res.status}`, res);
    return res.data;
  } catch (err) {
    debug("[updateAddress]", err);
    return undefined;
  }
}
