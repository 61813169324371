import React, { useContext, useState } from "react";

import ModalNotifications from "~/src/components/modals/ModalNotifications";
import { ContextModalType } from "~/src/types/context";
import { ModalConfig } from "~/src/types/modals";

const initialState: ContextModalType = {
  isOpen: false,
  open: () => undefined,
  close: () => undefined,
  setContent: () => undefined,
};

const ContextModal = React.createContext<ContextModalType>(initialState);

export function useModalContext() {
  return useContext(ContextModal);
}

export function ContextModalProvider({ children }: { children: React.ReactNode }) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [content, setContent] = useState<React.ReactNode>(null);
  const [config, setConfig] = useState<ModalConfig | undefined>();

  function openModal(elem?: React.ReactNode, _config?: ModalConfig) {
    setContent(elem);
    setConfig(_config);
    setIsOpen(true);
  }

  function closeModal() {
    setContent(null);
    config?.onClose?.();
    setIsOpen(false);
  }

  function setModalContent(elem: React.ReactNode) {
    setContent(elem);
  }

  function handleClickOutside() {
    if (config?.disableClickOutside) return;
    closeModal();
  }

  return (
    <ContextModal.Provider value={{ isOpen, open: openModal, close: closeModal, setContent: setModalContent }}>
      <ModalNotifications
        onClickOutside={handleClickOutside}
        isOpen={isOpen}
        content={content}
        closeModal={closeModal}
        config={config}
      />
      {children}
    </ContextModal.Provider>
  );
}
