import moment from "moment";

import { GetAvailabilitiesPayload } from "~/src/types/appointments";
import {
  DayInfo,
  DayName,
  DayNumber,
  FullDate,
  MonthInfo,
  MonthNameFull,
  MonthNameShort,
  MonthNumber,
  ParsedAvailabiltiesBlocks,
  SelectedTimeBlock,
  Time,
  TimeBlock,
} from "~/src/types/dates";
import { User } from "~/src/types/models";
import { capitalizeFirstLetter } from "~/src/utils/text";

import "moment/locale/es";

moment.locale("es");

function parseMoment(fullDate: FullDate): moment.Moment {
  return moment(fullDate, "YYYY-MM-DD");
}

function capitalizeAndRemoveDot(momentSpanishFormattedMonth: string) {
  return capitalizeFirstLetter(momentSpanishFormattedMonth).replace(".", "");
}

function fullDateToMonthNames(fullDate: FullDate): MonthInfo {
  const parsedMoment = parseMoment(fullDate);
  return {
    number: parsedMoment.format("MM") as MonthNumber,
    short: capitalizeAndRemoveDot(parsedMoment.format("MMM")) as MonthNameShort,
    full: capitalizeAndRemoveDot(parsedMoment.format("MMMM")) as MonthNameFull,
  };
}

function fullDateToDayNumberName(fullDate: FullDate): DayInfo {
  const parsedMoment = parseMoment(fullDate);
  return {
    number: parsedMoment.format("DD") as DayNumber,
    name: capitalizeAndRemoveDot(parsedMoment.format("dddd")) as DayName,
  };
}

export function getPreviousAndNextMonthInfo(
  current: MonthInfo,
  available: MonthInfo[],
): { prev: MonthInfo | undefined; next: MonthInfo | undefined } {
  const currentIndex = available.map((monthInfo) => monthInfo.number).indexOf(current.number);
  const prev = currentIndex - 1 >= 0 ? available[currentIndex - 1] : undefined;
  const next = currentIndex + 1 <= available.length - 1 ? available[currentIndex + 1] : undefined;
  return { prev, next };
}

export function getPreviousAndNextDayInfo(
  current: DayInfo,
  available: DayInfo[],
): {
  subprev: DayInfo | undefined;
  prev: DayInfo | undefined;
  next: DayInfo | undefined;
  supranext: DayInfo | undefined;
} {
  const currentIndex = available.map((dayInfo) => dayInfo.number).indexOf(current.number);
  const subprev = currentIndex - 2 >= 0 ? available[currentIndex - 2] : undefined;
  const prev = currentIndex - 1 >= 0 ? available[currentIndex - 1] : undefined;
  const next = currentIndex + 1 <= available.length - 1 ? available[currentIndex + 1] : undefined;
  const supranext = currentIndex + 2 <= available.length - 1 ? available[currentIndex + 2] : undefined;
  return { subprev, prev, next, supranext };
}

export function parseAvailability(
  blocks: GetAvailabilitiesPayload["block_based_availability"],
  disableFrom?: string | undefined,
): ParsedAvailabiltiesBlocks {
  // Note: Is there a stronger Typescript parser for keys() and entries() methods so it can infer it? lol
  const fullDates = Object.keys(blocks) as FullDate[];
  const blocksEntries = Object.entries(blocks) as [FullDate, [Time, Time][]][];

  /**
   * Trick to get non repeated months
   */
  const fullDatesAux = [...new Set(fullDates.map((fd) => `${fd.split("-").slice(0, 2).join("-")}-01`))];
  const availableMonths = fullDatesAux.map(fullDateToMonthNames);

  const availableDays: Partial<Record<MonthNameShort, { number: DayNumber; name: DayName }[]>> = {};
  for (const be of blocksEntries) {
    if (!availableDays[fullDateToMonthNames(be[0]).short]) {
      availableDays[fullDateToMonthNames(be[0]).short] = [fullDateToDayNumberName(be[0])];
    } else {
      availableDays[fullDateToMonthNames(be[0]).short]!.push(fullDateToDayNumberName(be[0]));
    }
  }

  const availableTimes: Partial<Record<MonthNameShort, Partial<Record<DayNumber, TimeBlock[]>>>> = {};
  for (const be of blocksEntries) {
    const monthNameShort = fullDateToMonthNames(be[0]).short;
    const dayNumber = fullDateToDayNumberName(be[0]).number;
    if (!availableTimes[monthNameShort]) {
      /**
       * Add month name key, so later we add their available days
       */
      availableTimes[monthNameShort] = {};
    }
    if (!availableTimes[monthNameShort]![dayNumber]) {
      /**
       * Add day number key, so later we add available time blocks
       */
      availableTimes[monthNameShort]![dayNumber] = [];
    }
    /**
     * Add start and end of each time block
     */
    for (const timeBlock of be[1]) {
      if (disableFrom && timeBlock[1] > disableFrom) {
        break;
      }
      availableTimes[monthNameShort]![dayNumber]!.push({ start: timeBlock[0], end: timeBlock[1] });
    }
  }

  return { availableMonths, availableDays, availableTimes };
}

export function humanizeTime(time: TimeBlock): string {
  const start = `${time.start.split(":").slice(0, 2).join(":")}hrs`;
  const end = `${time.end.split(":").slice(0, 2).join(":")}hrs`;
  return `${start} - ${end}`;
}

export function humanizeTimeBlock(stb: SelectedTimeBlock): string {
  return moment(`${stb.month.number}-${stb.day.number}`, "MM-DD").format("dddd D [de] MMMM [de] YYYY");
}

// TODO: store year on parsedAvailability
export function parseSubmitDate({
  year = "2022",
  month,
  day,
  time,
}: {
  month: MonthInfo;
  day: DayInfo;
  time: TimeBlock;
} & { year?: string }): {
  start: string;
  end: string;
} {
  const parsedSubmitDate = {
    start: `${year}-${month.number}-${day.number} ${time.start}`,
    end: `${year}-${month.number}-${day.number} ${time.end}`,
  };
  return parsedSubmitDate;
}

export function parseUserDateOfBirth(user: User) {
  const parsedUser = { ...user, ["date_of_birth"]: `${user["date_of_birth"]} 12:00:00` };
  return parsedUser;
}

export function parseUserDateOfBirthFromBackend(dob: string) {
  const parsed = moment(dob).format("YYYY-MM-DD");
  return parsed;
}
