import React from "react";

function CrossMobileIcon(props: React.ComponentPropsWithoutRef<"svg"> & { stroke?: string }) {
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.99977 1L0.999999 9.99978"
        stroke={props?.stroke || "#191A18"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.00011 1L9.99989 9.99978"
        stroke={props?.stroke || "#191A18"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CrossMobileIcon;
