import React from "react";

import { GatsbyBrowser } from "gatsby";

import { ContextProvider } from "~/src/context";
import { ContextModalProvider } from "~/src/context/notifications";
import namespaced from "~/src/utils/debug";
import { isDev, isStaging, isProd } from "~/src/utils/environment";

import "./src/styles/global.css";

/**
 * Gatsby Browser API Docs
 * https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#apis
 */

const debug = namespaced("gatsby-browser", { includeStaging: true });

/**
 * Called when the Gatsby browser runtime first starts.
 */
export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  if (isDev) {
    debug("App environment\n", { isDev, isStaging, isProd });
  } else if (isStaging) {
    debug("App environment\n", { isDev, isStaging, isProd });
    return;
  } else if (isProd) {
  }
};

/**
 * There is an equivalent hook in Gatsby SSR API. It is recommended to use both APIs together.
 */
export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => (
  <ContextProvider>
    <ContextModalProvider>{element}</ContextModalProvider>
  </ContextProvider>
);
