import { SupportedCountry, SupportedRegion } from "~/src/constants/places";
import { CreateUserAddress, ParsedAddress } from "~/src/types/addresses";
import { Address } from "~/src/types/models";

export function getAddressFull(address: ParsedAddress) {
  if (!address) return "";
  return `${address.street}${address.number ? ` ${address.number}, ` : ", "}${address.commune}, ${address.region}${
    address.country ? `, ${address.country}` : ""
  }`;
}

export function getAddressShort(address: ParsedAddress) {
  return `${address.street}${address.number ? ` ${address.number}` : ""}`;
}

export function getAddressBrief(address: ParsedAddress) {
  return `${address.street} ${address.number}, ${address.commune}`;
}

export function getAddressNormal(address: ParsedAddress) {
  const parsedRegionName =
    address.region === "Región Metropolitana de Santiago" ? "Región Metropolitana" : address.region;
  return `${address.street} ${address.number}, ${address.commune}, ${parsedRegionName}`;
}

export function parseRegionName(region: SupportedRegion) {
  if (region === "Región Metropolitana de Santiago") {
    return "Región Metropolitana";
  }
  return region;
}

export function parseTargetAddress(address: string): string {
  const parsed = address.replace("Región Metropolitana de Santiago", "Región Metropolitana");
  return parsed;
}

export function parseAddressFromBackend(address: Address | undefined): ParsedAddress | undefined {
  if (!address) return undefined;

  let streetNumber: string | undefined = address.street_number;
  let streetName: string | undefined = address.street_name;
  /**
   * We need this RegExp to find the street name and the street number if address was created before web app v3
   */
  const matched = address.address.match(/\d+/);
  if (!streetNumber) streetNumber = matched?.[0];
  if (!streetName) streetName = address.address.slice(0, matched?.index).trim();
  if (!streetNumber || !streetName) {
    // As we don't know the street number or the street name, then we do not add it
    return undefined;
  }
  /**
   * End RegExp for street number & street name
   */
  const parsedAddress = {
    id: address.id,
    street: streetName,
    number: streetNumber,
    commune: address.comuna,
    region: address.region,
    // Country fallback is exclusively for older Addresses than web app v3 release
    country: (address.country || "Chile") as SupportedCountry,
    zip_code: address.zip_code || "",
    apartment: address.apartment_number,
    type: address.residence_type,
    latitude: address.lat,
    longitude: address.lng,
    comment: address.additional_info,
  };
  return parsedAddress;
}

export function parseAddressForBackend(address: ParsedAddress): CreateUserAddress["data"] | undefined {
  if (!address) return undefined;
  const parsed: CreateUserAddress["data"] = {
    address: getAddressFull(address),
    street_number: address.number!,
    comuna: address.commune,
    region: address.region,
    country: address.country,
    zip_code: address.zip_code,
    lat: address.latitude,
    lng: address.longitude,
    residence_type: address.type || "unknown",
    apartment_number: address.apartment || "",
    additional_info: address.comment || "",
  };
  return parsed;
}

/**
 * Compares 2 ParsedAddress checking if their latitude and longitude are both the same
 */
export function isSameAddress(a1: ParsedAddress, a2: ParsedAddress): boolean {
  if (String(a1.latitude) === String(a2.latitude) && String(a1.longitude) === String(a2.longitude)) {
    return true;
  } else {
    return false;
  }
}
