import { isDev, isStaging } from "~/src/utils/environment";

/**
 * JavaScript console with colorful namespaces
 *
 * if namespace includes key, then will get color and background
 */
const debuggingColors = {
  ["gatsby-"]: { color: "#ffffff", background: "#d92121" },
  ["api-client"]: { color: "#ffffff", background: "#de8d08" },
  ["context"]: { color: "#ffffff", background: "#0032F8" },
  ["pages"]: { color: "#ffffff", background: "#800080" },
  ["components"]: { color: "#ffffff", background: "#006400" },
  ["utils"]: { color: "#ffffff", background: "#C75151" },
  ["hooks"]: { color: "#ffffff", background: "#6B2907" },
};

function namespaced(namespace: string, options?: { includeStaging?: boolean }) {
  return function debug(msg: any, ...rest: any[]) {
    if (isDev || (options?.includeStaging && isStaging)) {
      const keys = Object.keys(debuggingColors);
      const match = keys.find((k) => namespace.includes(k)) as keyof typeof debuggingColors | undefined;
      if (match) {
        const colors = debuggingColors[match];
        // eslint-disable-next-line no-console
        console.log(
          `%c${namespace}\n`,
          `background: ${colors.background}; color: ${colors.color}`,
          msg,
          ...rest,
          "\n\n",
        );
      } else {
        // eslint-disable-next-line no-console
        console.log(`${namespace}\n`, msg, ...rest, "\n\n");
      }
    }
  };
}

export default namespaced;
