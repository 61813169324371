import { encode, decode } from "base-64";

import { ContextValueType } from "~/src/types/context";
import namespaced from "~/src/utils/debug";

/**
 * This module is made to hide information from common users
 * and make interactions with local storage robust
 */

const debug = namespaced("utils/localStorage");

type AllowedLocalStorageKeys = keyof ContextValueType | "resetSchedulingOnNextMount";

function getItem<T>(key: AllowedLocalStorageKeys): T | undefined {
  try {
    const bKey = encode(key);
    const bValue = localStorage.getItem(bKey);
    if (!bValue) return undefined;
    const value = JSON.parse(decode(bValue)) as T;
    debug(`Success to get item with key ${key}, and value`, value);
    return value;
  } catch (err) {
    debug(`Failed to get item with key ${key}. Error: ${err}`);
    return undefined;
  }
}

function setItem(key: AllowedLocalStorageKeys, value: any): boolean {
  try {
    const bKey = encode(key);
    const bValue = encode(JSON.stringify(value));
    localStorage.setItem(bKey, bValue);
    debug(`Success to set item with key ${key}, and value`, value);
    return true;
  } catch (err) {
    debug(`Failed to set item with key ${key}, and value ${value}. Error: ${err}`);
    return false;
  }
}

const wrappedLocalStorage = { getItem, setItem };

export default wrappedLocalStorage;
