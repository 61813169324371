import React, { ReactNode, useEffect, useRef, useState } from "react";

import clsx from "clsx";
import ReactDOM from "react-dom";

import ModalWrapper from "~/src/components/modals/ModalWrapper";
import useOnClickOutside from "~/src/hooks/useOnClickOutside";
import CrossMobileIcon from "~/src/icons/CrossMobileIcon";
import { ModalConfig, ModalContentProps } from "~/src/types/modals";

type ModalNotificationType = {
  isOpen: boolean;
  content: ModalContentProps | ReactNode;
  onClickOutside?: () => void;
  closeModal: () => void;
  config?: ModalConfig;
  navigateToHomeOnClose?: boolean;
};

function ModalNotification({ isOpen, content, onClickOutside, closeModal, config }: ModalNotificationType) {
  const [modalContainer, setModalContainer] = useState<HTMLElement | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(containerRef, function handleModalClickOutside() {
    onClickOutside?.();
  });

  useEffect(() => {
    if (typeof document !== "undefined") {
      const elem = document.getElementById("modals") || document.body;
      setModalContainer(elem);
    }
  }, []);

  return (
    <React.Fragment>
      {isOpen &&
        ReactDOM.createPortal(
          <ModalWrapper>
            <div
              ref={containerRef}
              className={clsx(
                "rounded-2xl",
                "bg-examedi-white-light",
                "drop-shadow-xl md:drop-shadow-2xl",
                "relative",
                !config?.disableParentPadding && "px-5 sm:px-8 lg:px-12 py-8 md:pt-16 md:pb-10",
              )}
            >
              {!config?.disableCrossButton && (
                <div
                  className={clsx("absolute", "cursor-pointer", "top-2 right-2 md:top-4 md:right-4", "p-4")}
                  onClick={() => closeModal()}
                >
                  <CrossMobileIcon stroke={config?.crossButtonColor} />
                </div>
              )}
              {content}
            </div>
          </ModalWrapper>,
          modalContainer!,
        )}
    </React.Fragment>
  );
}

export default React.memo(ModalNotification);
