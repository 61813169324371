import { AxiosError } from "axios";
import qs from "qs";

import client from "~/src/api-client";
import {
  CreateAppointment,
  CreateAppointmentPayload,
  GetAppointmentPayload,
  GetAppointmentReceiptPayload,
  GetAppointmentResultPayload,
  GetAvailabilities,
  GetAvailabilitiesPayload,
  GetSingleAppointmentPayload,
  SetAppointmentDate,
  SetAppointmentDatePayload,
  SetPatientsV3,
  SetPatientsV3Payload,
  UpdateAppointment,
  UpdateAppointmentPayload,
} from "~/src/types/appointments";
import { Appointment, Receipt, Result } from "~/src/types/models";
import { parseUserDateOfBirth } from "~/src/utils/dates";
import namespaced from "~/src/utils/debug";
import { parseUserSymptoms } from "~/src/utils/symptoms";
import { parseUserEmail } from "~/src/utils/text";

const debug = namespaced("api-client/appointments");

export async function createAppointment(data: CreateAppointment): Promise<CreateAppointmentPayload | undefined> {
  try {
    const res = await client.post<CreateAppointmentPayload>("/appointments/create_multiple_v2/", {
      ...data,
    });
    debug(`[createAppointment] response was ${res.status}`, res);
    return res.data;
  } catch (err) {
    debug("[createAppointment]", err);

    return undefined;
  }
}

export async function getAvailabilities({
  appointmentId,
}: GetAvailabilities): Promise<GetAvailabilitiesPayload["block_based_availability"] | undefined> {
  try {
    const res = await client.get<GetAvailabilitiesPayload>(
      `/multiple-availabilities-v2?${qs.stringify(
        {
          appointment_id: appointmentId,
        },
        { addQueryPrefix: false },
      )}`,
    );
    debug(`[getAvailabilities] response was ${res.status}`, res);
    return res.data["block_based_availability"] ? res.data["block_based_availability"] : undefined;
  } catch (err) {
    debug("[getAvailabilities]", err);

    return undefined;
  }
}

export async function setAppointmentDate({
  appointmentId,
  startDate,
  endDate,
}: SetAppointmentDate): Promise<SetAppointmentDatePayload | undefined> {
  try {
    const res = await client.post<SetAppointmentDatePayload>(`/appointments/${appointmentId}/set_date_v2/`, {
      begin_date: startDate,
      end_date: endDate,
    });
    debug(`[setAppointmentDate] response was ${res.status}`, res);
    return res.data;
  } catch (err) {
    debug("[setAppointmentDate]", err);

    return undefined;
  }
}

export async function setPatientsV3({
  appointmentId,
  addressId,
  users,
  target_apartment_number,
  target_residence_type,
  target_address_additional_info,
}: SetPatientsV3): Promise<SetPatientsV3Payload | string | undefined> {
  try {
    const res = await client.post<SetPatientsV3Payload>(`/appointments/${appointmentId}/set_patients_v3/`, {
      address_id: addressId,
      /**
       * Is it faster to parse all in one mapping? :thinking:
       * Both behave like O(3*n) right?
       * I guess this looks cleaner
       */
      users: users.map(parseUserDateOfBirth).map(parseUserSymptoms).map(parseUserEmail),
      target_apartment_number,
      target_residence_type,
      target_address_additional_info,
    });
    debug(`[setPatientsV3] response was ${res.status}`, res);
    return res.data;
  } catch (err) {
    debug("[setPatientsV3]", err);

    const error = err as AxiosError;
    if (error.isAxiosError) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const errorMessage = error?.response?.data?.err as string;
      return errorMessage;
    }
    return undefined;
  }
}

export async function updateAppointment(
  token: string,
  { appointmentId, data }: UpdateAppointment,
): Promise<UpdateAppointmentPayload | undefined> {
  try {
    const res = await client.patch<UpdateAppointmentPayload>(`/appointments/${appointmentId}/`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    debug(`[updateAppointment] response was ${res.status}`, res);
    return res.data;
  } catch (err) {
    debug("[updateAppointment]", err);

    return undefined;
  }
}

export async function rescheduleAppointment(
  token: string,
  { appointmentId, data }: UpdateAppointment,
): Promise<UpdateAppointmentPayload | undefined> {
  try {
    const res = await client.put<UpdateAppointmentPayload>(`/appointments/${appointmentId}/reschedule/`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    debug(`[rescheduleAppointment] response was ${res.status}`, res);
    return res.data;
  } catch (err) {
    debug("[rescheduleAppointment]", err);

    return undefined;
  }
}

export const getPendingAppointments = async (token: string): Promise<Appointment[] | undefined> => {
  try {
    const res = await client.get<GetAppointmentPayload>("/users/pending_appointments/", {
      headers: { Authorization: `Bearer ${token}` },
    });
    debug(`[fetchPendingAppointments] response was ${res.status}`, res);
    return res.data.data;
  } catch (err) {
    debug("[fetchPendingAppointments]", err);
    // TODO: handle Unauthorized error without LogRocket

    return undefined;
  }
};

export const getFinishedAppointments = async (token: string): Promise<Appointment[] | undefined> => {
  try {
    const res = await client.get<GetAppointmentPayload>("/users/finished_appointments/", {
      headers: { Authorization: `Bearer ${token}` },
    });
    debug(`[fetchFinishedAppointments] response was ${res.status}`, res);
    return res.data.data;
  } catch (err) {
    debug("[fetchFinishedAppointments]", err);
    // TODO: handle Unauthorized error without LogRocket

    return undefined;
  }
};

export const getSingleAppointment = async (
  token: string | undefined,
  appointmentId: string,
): Promise<Appointment | undefined> => {
  try {
    const res = await client.get<GetSingleAppointmentPayload>(`/appointments/${appointmentId}/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    debug(`[getSingleAppointment] response was ${res.status}`, res);
    return res.data;
  } catch (err) {
    debug("[getSingleAppointment]", err);
    // TODO: handle Unauthorized error without LogRocket

    return undefined;
  }
};

export const getAppointmentReceipt = async (
  token: string | undefined,
  appointmentId: string,
): Promise<Receipt[] | undefined> => {
  try {
    const res = await client.get<GetAppointmentReceiptPayload>(`/appointments/${appointmentId}/retrieve_receipts/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    debug(`[getAppointmentReceipt] response was ${res.status}`, res);
    return res.data.data;
  } catch (err) {
    debug("[getAppointmentReceipt]", err);
    // TODO: handle Unauthorized error without LogRocket

    return undefined;
  }
};

export const getAppointmentResults = async (
  token: string | undefined,
  appointmentId: string,
): Promise<Result[] | undefined> => {
  try {
    const res = await client.get<GetAppointmentResultPayload>(`/appointments/${appointmentId}/retrieve_results/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    debug(`[getAppointmentResults] response was ${res.status}`, res);
    return res.data.data;
  } catch (err) {
    debug("[getAppointmentResults]", err);
    // TODO: handle Unauthorized error without LogRocket

    return undefined;
  }
};
