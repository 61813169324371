import React from "react";

type NotificationAddToCartProps = { itemName: string };

function NotificationAddToCart({ itemName }: NotificationAddToCartProps) {
  return (
    <div className="px-4 py-2 text-sm md:text-base lg:py-3 w-64 md:w-80 lg:w-96 drop-shadow-md grid grid-cols-12 bg-white opacity-90 z-50 border border-gray-200 rounded-xl">
      <div className="col-span-2 flex justify-start items-center">✅</div>
      <div className="col-span-10 truncate-on-2-lines">Agregaste {itemName} al carrito</div>
    </div>
  );
}

export default NotificationAddToCart;
