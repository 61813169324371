import axios from "axios";

import namespaced from "~/src/utils/debug";
import { isProd } from "~/src/utils/environment";

const debug = namespaced("api-client/index");

// const API_URL = "https://api-dev.examedi.com";
const API_URL = !isProd ? "https://api-dev.examedi.com" : "https://api.examedi.com";

// LOCAL
// const API_URL = "http://localhost:8000";

debug(`API URL is ${API_URL}`);

const client = axios.create({
  baseURL: API_URL,
});

export default client;
