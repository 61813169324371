module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"examedi-web-app","short_name":"Examedi","start_url":"/","background_color":"#ffffff","theme_color":"#039Be5","display":"minimal-ui","icon":"src/images/brand/examedi-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"15794bc125c57e0e7220d90603b4d6f6"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    }]
