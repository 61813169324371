import { Medic } from "../types/medic";

import { ContextUpdateType, ContextValueType } from "~/src/types/context";

export const defaultContextValue: ContextValueType = {
  preLoading: false,
  session: undefined,
  user: undefined,
  medic: undefined,
  isLoggedIn: false,
  cart: {},
  cartStep: 0,
  cartItemsAmount: 0,
  currentAppointmentId: null,
  allAvailableServices: [],
  allAvailablePacks: [],
  gotAllPacksResponse: false,
  fastCovidServices: {
    express: undefined,
    antigenos: undefined,
    nasal: undefined,
    salival: undefined,
    fonasa: undefined,
    sameDay: undefined,
  },
  selectedAddress: undefined,
  selectedTimeBlock: undefined,
  allAddresses: [],
  isForcingAddress: false,
  rehydrated: false,
  salesSource: "marketplace",
  UTMId: null,
  currentPatientAmount: undefined,
  pendingAppointments: undefined,
  finishedAppointments: undefined,
  alreadyShowedWelcomePopup: false,
};

/* eslint-disable @typescript-eslint/no-unused-vars */
export const defaultContextUpdate: ContextUpdateType = {
  // @ts-expect-error never read
  setSessionKeys: (keys: Session) => undefined,
  // @ts-expect-error never read
  setPreLoadingStatus: (status: boolean) => undefined,
  // @ts-expect-error never read
  setUserKeys: (keys: User) => undefined,
  // @ts-expect-error never read
  setMedic: (keys: Medic) => undefined,
  destroySession: () => undefined,
  // @ts-expect-error never read
  addToCart: (item: CartService | CartPack, type: "service" | "pack", amount?: number) => undefined,
  // @ts-expect-error never read
  removeFromCart: (item: CartService | CartPack) => undefined,
  // @ts-expect-error never read
  removeAllFromCart: (item: CartService | CartPack) => undefined,
  resetCart: () => undefined,
  resetCurrentScheduling: () => undefined,
  resetSchedulingOnMount: () => undefined,
  // @ts-expect-error never read
  updateCartStep: (from: "carrito-de-compras" | "agendamiento" | "mis-datos") => undefined,
  // @ts-expect-error never read
  setCurrentAppointment: (appointmentId: string) => undefined,
  // @ts-expect-error never read
  setPatientAmount: (n: number) => undefined,
  // @ts-expect-error never read
  setFastServices: (services: FastCovidServices) => undefined,
  // @ts-expect-error never read
  setCurrentAddress: (newAddress: ParsedAddress) => undefined,
  // @ts-expect-error never read
  setCurrentTimeBloc: (newTimeBlock: SelectedTimeBlock) => undefined,
  // @ts-expect-error never read
  refreshAllAddresses: (allAddresses: ParsedAddress[]) => undefined,
  resetAddresses: () => undefined,
  // @ts-expect-error never read
  setForcingAddress: (shouldScrollToTop?: boolean) => undefined,
  // @ts-expect-error never read
  setSalesSource: (newSalesSource: string) => undefined,
  // @ts-expect-error never read
  setUTMId: (newUTMId: string) => undefined,
};
/* eslint-enable @typescript-eslint/no-unused-vars */
