import React, { useContext, useEffect, useState } from "react";

import { store } from "react-notifications-component";

import { Medic } from "../types/medic";

import { createUserAddress, getUserAddresses } from "~/src/api-client/addresses";
import { getFinishedAppointments, getPendingAppointments } from "~/src/api-client/appointments";
import NotificationAddToCart from "~/src/components/notifications/NotificationAddToCart";
import { defaultContextUpdate, defaultContextValue } from "~/src/constants/context";
import { ParsedAddress } from "~/src/types/addresses";
import { AllSettledResult } from "~/src/types/common";
import { Cart, CartStep, ContextUpdateType, ContextValueType, Session } from "~/src/types/context";
import { SelectedTimeBlock } from "~/src/types/dates";
import { Appointment, CartPack, CartService, Pack, SearchItem, UserLoggedIn } from "~/src/types/models";
import { FastCovidServices } from "~/src/types/services";
import { getAddressFull, isSameAddress, parseAddressForBackend, parseAddressFromBackend } from "~/src/utils/address";
import { countCartItems } from "~/src/utils/context";
import namespaced from "~/src/utils/debug";
import { isDev } from "~/src/utils/environment";
import EventService from "~/src/utils/EventService";
import wrappedLocalStorage from "~/src/utils/localStorage";

const debug = namespaced("context/index");

/**
 * HOW TO ADD A VALUE OR A VALUE-HANDLER
 *    1. Go to ~/src/types/context and add types (i.e. `ValueType` and `SetValueType`)
 *    2. Go to ~/src/constants/context and add defaults
 *    3. In this file, create state values & their handlers
 *        - Use `const [value, setCurrentValue] = useState<ValueType>() in ContextProvider`
 *        - Create a method `function setValue: SetValueType` that calls our internal method `setCurrentValue`
 *        - Export value & setValue on props.value from `ContextValue` & `ContextUpdate`
 *    > Why? This way we create an interface on top of useState setter
 *           so we can extend or modify its behavior easily
 */
const ContextValue = React.createContext<ContextValueType>(defaultContextValue);
const ContextUpdate = React.createContext<ContextUpdateType>(defaultContextUpdate);

/**
 * HOW TO SUBSCRIBE TO A VALUE
 *    1. In your component file: `import { useContextValue } from "~/src/context"`
 *    2. In your component body: `const { value } = useContextValue();`
 *    3. Subscription ready, you can safely use it in your component's `useEffect`s
 */
export function useContextValue() {
  return useContext(ContextValue);
}

/**
 * HOW TO GET A VALUE-HANDLER
 *    1. In your component file: `import { useContextUpdate } from "~/src/context"`
 *    2. In your component body: `const { setValue } = useContextUpdate();`
 *    3. Voilà
 */
export function useContextUpdate() {
  return useContext(ContextUpdate);
}

/**
 * ContextProvider defines the "reducer" methods and provides them to ContextUpdate
 * To use those methods, import the useContextUpdate function from this file to your component
 *
 * ContextProvider also provides the values from the context to ContextValue
 * To use those values, import the useContextValues function from this file to your component
 */
export function ContextProvider({ children }: { children: React.ReactNode }) {
  /* eslint-disable */ // So it doesn't break lines here <:) (specify the rule if you can)
  const [session, setSession] = useState<Session | undefined>(defaultContextValue.session);
  const [preLoading, setPreLoading] = useState<boolean>(defaultContextValue.preLoading);
  const [user, setUser] = useState<UserLoggedIn | undefined>(defaultContextValue.user);
  const [medic, setMedic] = useState<Medic | Record<string, unknown> | undefined>(defaultContextValue.medic);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(defaultContextValue.isLoggedIn);
  const [cart, setCart] = useState<Cart>(defaultContextValue.cart);
  const [cartStep, setCartStep] = useState<CartStep>(defaultContextValue.cartStep);
  const [cartItemsAmount, setCartItemsAmount] = useState<number>(defaultContextValue.cartItemsAmount);
  const [currentAppointmentId, setCurrentAppointmentId] = useState<string | null>(defaultContextValue.currentAppointmentId);
  const [allAvailableServices, setAllAvailableServices] = useState<SearchItem[]>(defaultContextValue.allAvailableServices);
  const [allAvailablePacks, setAllAvailablePacks] = useState<Pack[]>(defaultContextValue.allAvailablePacks);
  const [gotAllPacksResponse, setGotAllPacksResponse] = useState<boolean>(defaultContextValue.gotAllPacksResponse);
  const [fastCovidServices, setFastCovidServices] = useState<FastCovidServices>(defaultContextValue.fastCovidServices);
  const [selectedAddress, setSelectedAddress] = useState<ParsedAddress | undefined>(defaultContextValue.selectedAddress);
  const [selectedTimeBlock, setSelectedTimeBlock] = useState<SelectedTimeBlock | undefined>(defaultContextValue.selectedTimeBlock);
  const [allAddresses, setAllAddresses] = useState<ParsedAddress[]>(defaultContextValue.allAddresses);
  const [isForcingAddress, setIsForcingAddress] = useState<boolean>(defaultContextValue.isForcingAddress);
  const [rehydrated, setRehydrated] = useState<boolean>(defaultContextValue.rehydrated);
  const [salesSource, setCurrentSalesSource] = useState<string>(defaultContextValue.salesSource);
  const [UTMId, setCurrentUTMId] = useState<string | null>(defaultContextValue.UTMId);
  const [currentPatientAmount, setCurrentPatientAmount] = useState<number | undefined>(defaultContextValue.currentPatientAmount);
  const [pendingAppointments, setCurrentPendingAppointments] = useState<Appointment[] | undefined>(defaultContextValue.pendingAppointments);
  const [finishedAppointments, setCurrentFinishedAppointments] = useState<Appointment[] | undefined>(defaultContextValue.finishedAppointments);
  const [alreadyShowedWelcomePopup, setAlreadyShowedWelcomePopup] = useState<boolean>(defaultContextValue.alreadyShowedWelcomePopup);
  /* eslint-enable */

  function setPreLoadingStatus(status: boolean) {
    debug("setting preLoading");
    setPreLoading(status);
  }

  function setSessionKeys(keys: Session) {
    debug("called setSessionKeys");
    setSession((prevSessionState) => {
      const newSessionState = { ...prevSessionState, ...keys };
      wrappedLocalStorage.setItem("session", newSessionState);
      if (newSessionState.accessToken) {
        setIsLoggedIn(true);
        wrappedLocalStorage.setItem("isLoggedIn", true);
      } else {
        setIsLoggedIn(false);
        wrappedLocalStorage.setItem("isLoggedIn", false);
      }
      return newSessionState;
    });
  }

  function setMedicKeys(medicData: Medic) {
    debug("called setMedicKeys");
    setMedic(() => {
      wrappedLocalStorage.setItem("medic", medicData);
      return medicData;
    });
  }

  function setUserKeys(keys: UserLoggedIn) {
    debug("called setUserKeys");
    setUser((prevUserState) => {
      // TODO: Remove when all accounts has the document_type/number
      if (!keys.document_number && keys.rut && keys.rut.length > 0) {
        keys.document_number = keys.rut;
        keys.document_type = "ci";
      }
      const newUserState = { ...prevUserState, ...keys };
      wrappedLocalStorage.setItem("user", newUserState);
      return newUserState;
    });
  }

  function destroySession() {
    debug("called destroySession");
    setSession(undefined);
    setUser(undefined);
    setIsLoggedIn(false);
    setPendingAppointments(undefined);
    setFinishedAppointments(undefined);
    resetAddresses();
    resetCart();
    wrappedLocalStorage.setItem("allAddresses", []);
    wrappedLocalStorage.setItem("session", undefined);
    wrappedLocalStorage.setItem("user", undefined);
    wrappedLocalStorage.setItem("isLoggedIn", false);
  }

  /**
   * Note
   *
   * This method usage is only for this file, setCurrentAddress() should do the job.
   * It's because we assume that whenever a user creates a new address, he wants to use it right away.
   *
   */
  function addAddress(newAddress: ParsedAddress): boolean {
    debug("called addAddress");
    let added = false;
    setAllAddresses((prevAllAddresses) => {
      if (!prevAllAddresses.some((pa) => isSameAddress(pa, newAddress))) {
        const newAllAddresses = [newAddress, ...prevAllAddresses];
        added = true;
        wrappedLocalStorage.setItem("allAddresses", newAllAddresses);
        return newAllAddresses;
      } else {
        return prevAllAddresses;
      }
    });
    return added;
  }

  function removeAddress(toRemove: ParsedAddress): boolean {
    debug("called removeAddress");
    let removed = false;
    setAllAddresses((prevAllAddresses) => {
      const newAllAddresses = prevAllAddresses.filter((pa) => !isSameAddress(pa, toRemove));
      removed = prevAllAddresses.length > newAllAddresses.length;
      wrappedLocalStorage.setItem("allAddresses", newAllAddresses);
      if (selectedAddress && isSameAddress(toRemove, selectedAddress)) {
        setSelectedAddress(undefined);
        wrappedLocalStorage.setItem("selectedAddress", undefined);
      }
      return newAllAddresses;
    });
    return removed;
  }

  function resetAddresses() {
    debug("called resetAddresses");
    setSelectedAddress(undefined);
    setAllAddresses([]);
    wrappedLocalStorage.setItem("selectedAddress", undefined);
    wrappedLocalStorage.setItem("allAddresses", []);
  }

  /**
   * - Sets the current address for schedule
   * - Removes forcing address
   * - Adds address to allAddresses in case it was not included
   * - If logged in, it creates the Address on backend if it was not included
   */
  async function setCurrentAddress(newCurrentAddress: ParsedAddress) {
    debug("called setCurrentAddress");
    addAddress(newCurrentAddress);
    setIsForcingAddress(false);
    wrappedLocalStorage.setItem("selectedAddress", newCurrentAddress);
    /**
     * If logged in create it in backend and store the serialized address
     * Else store it as is
     */
    const parsed = parseAddressForBackend(newCurrentAddress);
    if (
      parsed &&
      isLoggedIn &&
      session?.accessToken &&
      !allAddresses.some((pa) => isSameAddress(pa, newCurrentAddress))
    ) {
      const addressWithId = await createUserAddress({ token: session.accessToken, data: parsed });
      const reparsed = parseAddressFromBackend(addressWithId);
      if (reparsed) {
        addAddress(reparsed);
      }
    }
    setSelectedAddress(newCurrentAddress);
  }

  /**
   * - Overwrites allAddresses
   */
  function refreshAllAddresses(allAddressesParam: ParsedAddress[]) {
    debug("called refreshAllAddresses");
    setAllAddresses(allAddressesParam);
    wrappedLocalStorage.setItem("allAddresses", allAddressesParam);
  }

  function setCurrentTimeBlock(newCurrentTimeBlock: SelectedTimeBlock) {
    debug("called setCurrentTimeBlock");
    setSelectedTimeBlock(newCurrentTimeBlock);
    wrappedLocalStorage.setItem("selectedTimeBlock", newCurrentTimeBlock);
  }

  function setForcingAddress(shouldScrollToTop = true) {
    debug("called setForcingAddress");
    setIsForcingAddress(true);
    /**
     * As we are not sure about where is the user, we need to clean the cart
     */
    resetCart();

    /**
     * Note:
     *
     * Navigation with navigate() is made internally, so we don't need to set this on local storage
     * Actually, letting the user reload the webpage
     * to make dissapear the forcing address paraphernalia is good
     */
    // wrappedLocalStorage.setItem("isForcingAddress", true); // Don't
    if (shouldScrollToTop && window) window.scrollTo({ top: 0, behavior: "smooth" });
  }

  function addToCart(item: CartService | CartPack, type: "service" | "pack", amount = 1) {
    debug("called addToCart");
    debug("will check conditions & add item to cart");
    if (!rehydrated) {
      /**
       * TODO: how do we handle this case 🤔
       * IDEA: make an action queue (I don't think we will ever do this 😂)
       * For now we just don't do anything and user has to redo his action...
       * Anyways, he would have to be super fast, so this does not really matter
       */
      return;
    }

    debug("will add item to cart");
    let added = false;
    setCart((prevCartState) => {
      const prevCartStateItem = prevCartState[item.id];
      if (prevCartStateItem) {
        /**
         * There was already one of this item in the cart before
         */
        if (prevCartStateItem.amount > 0 && !item.allow_multiple_on_checkout) {
          debug("failed to add item to cart because it is not stackable and had a positive amount before");
          return prevCartState;
        }
        /**
         * Not allowed to add more than 1 pack for now
         */
        if (type === "pack" && Object.values(prevCartState).some((i) => i.type === "pack" && i.amount > 0)) {
          debug("failed to add item to cart because it is a pack and already have a pack in cart");
          return prevCartState;
        }
        const newCartState = {
          ...prevCartState,
          [item.id]: { item, type, amount: prevCartStateItem.amount + amount },
        };
        wrappedLocalStorage.setItem("cart", newCartState);
        added = true;
        return newCartState;
      } else {
        /**
         * First time this item is in the cart
         */

        /**
         * Not allowed to add more than 1 pack for now
         */
        if (type === "pack" && Object.values(prevCartState).some((i) => i.type === "pack" && i.amount > 0)) {
          debug("failed to add item to cart because it is a pack and already have a pack in cart");
          return prevCartState;
        }
        const newCartState = { ...prevCartState, [item.id]: { item, type, amount } };
        wrappedLocalStorage.setItem("cart", newCartState);
        added = true;
        return newCartState;
      }
    });
    if (added) {
      if (!selectedAddress) {
        EventService.dispatch("serviceAddedWithoutAddress");
      }
      store.addNotification({
        content: () => <NotificationAddToCart itemName={item.name} />,
        insert: "top",
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: { duration: 2000 },
      });
    }
  }

  function removeFromCart(item: CartService | CartPack) {
    debug("called removeFromCart");
    setCart((prevCartState) => {
      const prevCartStateItem = prevCartState[item.id];
      if (!prevCartStateItem || prevCartStateItem["amount"] === 0) {
        return prevCartState;
      }
      const newCartState = {
        ...prevCartState,
        [item.id]: { ...prevCartStateItem, amount: prevCartStateItem["amount"] - 1 },
      };
      wrappedLocalStorage.setItem("cart", newCartState);
      return newCartState;
    });
  }

  function removeAllFromCart(item: CartService | CartPack) {
    debug("called removeAllFromCart");
    setCart((prevCartState) => {
      const prevCartStateItem = prevCartState[item.id];
      if (!prevCartStateItem || prevCartStateItem["amount"] === 0) {
        return prevCartState;
      }
      const newCartState = { ...prevCartState, [item.id]: { ...prevCartStateItem, amount: 0 } };
      wrappedLocalStorage.setItem("cart", newCartState);
      return newCartState;
    });
  }

  function resetCart() {
    debug("called resetCart");
    setCart({});
    wrappedLocalStorage.setItem("cart", {});
  }

  function resetCurrentScheduling() {
    debug("called resetCurrentScheduling");
    resetCart();
    resetCurrentAppointment();
  }

  function resetSchedulingOnMount() {
    wrappedLocalStorage.setItem("resetSchedulingOnNextMount", true);
  }

  function updateCartStep(from: "carrito-de-compras" | "agendamiento" | "mis-datos" | "pago-seguro") {
    setCartStep((prevCartStep) => {
      if (from === "carrito-de-compras") {
        if (cartItemsAmount > 0) {
          return 1;
        } else {
          return 0;
        }
      } else if (from === "agendamiento") {
        return 2;
      } else if (from === "mis-datos") {
        return 3;
      } else if (from === "pago-seguro") {
        return 4;
      }
      return prevCartStep;
    });
  }

  function setCurrentAppointment(appointmentId: string) {
    debug("called setCurrentAppointment");
    setCurrentAppointmentId((prevCurrentAppointmentId) => {
      debug(`New current appointment id: ${appointmentId}, old was: ${prevCurrentAppointmentId}`);
      wrappedLocalStorage.setItem("currentAppointmentId", appointmentId);
      return appointmentId;
    });
  }

  function setPatientAmount(n: number) {
    setCurrentPatientAmount(n);
    wrappedLocalStorage.setItem("currentPatientAmount", n);
  }

  function resetCurrentAppointment() {
    debug("called resetCurrentAppointment");
    setCurrentAppointmentId(null);
    // currentPatientAmount will be set to undefined in useEffect[currentAppointmentId]
    wrappedLocalStorage.setItem("currentAppointmentId", null);
    setSelectedTimeBlock(undefined);
    // Sets the utmId value to null when the appointment is cleared
    setUTMId(null);
  }

  function setSalesSource(newSalesSource: string) {
    debug("called setSalesSource");
    setCurrentSalesSource(newSalesSource);
  }

  function setUTMId(newUTMId: string | null) {
    debug("called setUTMId");
    wrappedLocalStorage.setItem("UTMId", newUTMId);
    setCurrentUTMId(newUTMId);
  }

  function setPendingAppointments(appointments: Appointment[] | undefined) {
    debug("called setPendingAppointments");
    setCurrentPendingAppointments(appointments);
  }

  function setFinishedAppointments(appointments: Appointment[] | undefined) {
    debug("called setFinishedAppointments");
    setCurrentFinishedAppointments(appointments);
  }

  /**
   * On mount local storage handler
   */
  useEffect(() => {
    try {
      debug("will rehydrate context with local storage");
      /**
       * First, check if we have to reset current scheduling
       */
      const lsCurrentSchedulingOnNextMount = wrappedLocalStorage.getItem<boolean>("resetSchedulingOnNextMount");
      if (lsCurrentSchedulingOnNextMount === true) {
        setCurrentAppointmentId(null);
        setCurrentPatientAmount(undefined);
        setSelectedTimeBlock(undefined);
        setCurrentUTMId(null);
        resetCart();
        wrappedLocalStorage.setItem("currentAppointmentId", null);
        wrappedLocalStorage.setItem("currentPatientAmount", undefined);
        wrappedLocalStorage.setItem("selectedTimeBlock", undefined);
        wrappedLocalStorage.setItem("resetSchedulingOnNextMount", false);
        wrappedLocalStorage.setItem("UTMId", null);
      } else {
        const lsCurrentAppointmentId = wrappedLocalStorage.getItem<string>("currentAppointmentId");
        const lsCurrentPatientAmount = wrappedLocalStorage.getItem<number>("currentPatientAmount");
        const lsSelectedTimeBlock = wrappedLocalStorage.getItem<SelectedTimeBlock>("selectedTimeBlock");
        const lsCart = wrappedLocalStorage.getItem<Cart>("cart");
        const lsUTMId = wrappedLocalStorage.getItem<string>("UTMId");
        if (lsCurrentAppointmentId !== undefined) setCurrentAppointmentId(lsCurrentAppointmentId);
        if (lsCurrentPatientAmount !== undefined) setCurrentPatientAmount(lsCurrentPatientAmount);
        if (lsSelectedTimeBlock !== undefined) setSelectedTimeBlock(lsSelectedTimeBlock);
        if (lsCart) setCart(lsCart);
        if (lsUTMId !== undefined) setCurrentUTMId(lsUTMId);
      }
      /**
       * The rest...
       */
      const lsSession = wrappedLocalStorage.getItem<Session>("session");
      const lsUser = wrappedLocalStorage.getItem<UserLoggedIn>("user");
      const lsIsLoggedIn = wrappedLocalStorage.getItem<boolean>("isLoggedIn");
      const lsAllAddresses = wrappedLocalStorage.getItem<ParsedAddress[]>("allAddresses");
      const lsMedic = wrappedLocalStorage.getItem<Medic>("medic");
      const lsSelectedAddress = wrappedLocalStorage.getItem<ParsedAddress>("selectedAddress");

      if (lsSession !== undefined) setSession(lsSession);
      if (lsUser !== undefined) setUser(lsUser);
      if (lsIsLoggedIn !== undefined) setIsLoggedIn(lsIsLoggedIn);
      if (lsAllAddresses !== undefined) setAllAddresses(lsAllAddresses);
      if (lsSelectedAddress !== undefined) setSelectedAddress(lsSelectedAddress);
      if (lsMedic !== undefined) setMedicKeys(lsMedic);

      setRehydrated(true);
      debug("success rehydrating context");
    } catch (err) {
      debug("failed rehydrating context");
    }
  }, []);

  /**
   * Load all available services & all packs for selected commune (or all if no selected commune)
   */
  useEffect(() => {
    const getAllServices = async () => {
      return [];
    };

    const getAllPacks = async () => {
      return [];
    };

    Promise.allSettled([getAllServices(), getAllPacks()]).then(async ([serviceList, packList]: AllSettledResult[]) => {
      const servicesAndPacks = [
        ...(serviceList && serviceList.status === "fulfilled" ? serviceList.value : []),
        ...(packList && packList?.status === "fulfilled" ? packList.value : []),
      ];
      const serviceIds = (servicesAndPacks as CartService[])?.map((value) => value.id);
      if (serviceIds.length === 0) {
        const address = getAddressFull(selectedAddress!);
        EventService.dispatch("AddressWithoutServicesAvailable", { message: address });
        // TODO: Backend should be avoid to save address witouth services
        // removeAddress(selectedAddress!);
      } else {
        const serviceIdsInShoppingCart = Object.values(cart)
          .filter((cartEntry) => cartEntry.amount > 0)
          .map((cartEntry) => cartEntry.item.id);
        if (serviceIdsInShoppingCart.length > 0) {
          const newCartState: Cart = Object.values(cart)
            .filter((cartEntry) => serviceIds.includes(cartEntry.item.id))
            .reduce((acc, elem) => ({ ...acc, [elem.item.id]: elem }), {});
          setCart(newCartState);
          wrappedLocalStorage.setItem("cart", newCartState);
          if (
            serviceIdsInShoppingCart.length !==
            Object.values(newCartState).filter((cartEntry) => cartEntry.amount > 0).length
          ) {
            const address = getAddressFull(selectedAddress!);
            EventService.dispatch("ServicesRemovedFromCart", { message: address });
          }
        }
      }
    });

    if (selectedAddress && window) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAddress]);

  useEffect(() => {
    debug("cart was updated\n", cart);
    const countCartItemsValue = countCartItems(cart);
    setCartItemsAmount(countCartItemsValue);

    setCartStep((prevCartStep) => {
      if (prevCartStep === 0 && countCartItemsValue > 0) {
        return 1;
      }
      if (prevCartStep === 1 && countCartItemsValue < 1) {
        return 0;
      }
      return prevCartStep;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  /**
   * When isLoggedIn is set to true for the first time after mounting the app
   * revalidate session while obtaining pending & finished appointments
   */
  useEffect(() => {
    if (isLoggedIn && session && user) {
      if (!user.document_number || !user.document_type) {
        /**
         * There was an error when registering: we were not receiveing this information
         * Solution: Force user to login again
         */
        destroySession();
        return;
      }

      const getAppointmentsData = async () => {
        const [resAddresses, resPending, resFinished] = await Promise.all([
          getUserAddresses(session.accessToken),
          getPendingAppointments(session.accessToken),
          getFinishedAppointments(session.accessToken),
        ]);
        if (resAddresses || resPending || resFinished) {
          if (resAddresses) {
            setAllAddresses(resAddresses);
            setSelectedAddress(selectedAddress ? selectedAddress : resAddresses.length ? resAddresses[0] : undefined);
          }
          setPendingAppointments(resPending);
          setFinishedAppointments(resFinished);
        } else {
          destroySession();
        }
      };
      getAppointmentsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, isLoggedIn, user]);

  useEffect(() => {
    debug("currentAppointmentId was updated\n", currentAppointmentId);
    if (currentAppointmentId) {
      setSelectedTimeBlock(undefined);
    } else {
      setCurrentPatientAmount(undefined);
    }
  }, [currentAppointmentId]);

  /* eslint-disable react-hooks/rules-of-hooks */
  if (isDev) {
    useEffect(() => {
      debug("session was updated\n", session);
    }, [session]);

    useEffect(() => {
      debug("user was updated\n", user);
    }, [user]);

    useEffect(() => {
      debug("cartStep was updated\n", cartStep);
    }, [cartStep]);

    useEffect(() => {
      debug("selectedAddress was updated\n", selectedAddress);
    }, [selectedAddress]);

    useEffect(() => {
      debug("allAddresses was updated\n", allAddresses);
    }, [allAddresses]);

    useEffect(() => {
      debug("isForcingAddress was updated\n", isForcingAddress);
    }, [isForcingAddress]);

    useEffect(() => {
      debug("salesSource was updated\n", salesSource);
    }, [salesSource]);

    useEffect(() => {
      debug("UTMId was updated\n", UTMId);
    }, [UTMId]);

    useEffect(() => {
      debug("currentPatientAmount was updated\n", currentPatientAmount);
    }, [currentPatientAmount]);
  }
  /* eslint-enable react-hooks/rules-of-hooks */

  return (
    <ContextValue.Provider
      value={{
        session,
        user,
        medic,
        preLoading,
        isLoggedIn,
        cart,
        cartStep,
        cartItemsAmount,
        currentAppointmentId,
        allAvailableServices,
        allAvailablePacks,
        gotAllPacksResponse,
        fastCovidServices,
        allAddresses,
        selectedAddress,
        selectedTimeBlock,
        isForcingAddress,
        rehydrated,
        salesSource,
        UTMId,
        currentPatientAmount,
        pendingAppointments,
        finishedAppointments,
        alreadyShowedWelcomePopup,
      }}
    >
      <ContextUpdate.Provider
        value={{
          setSessionKeys,
          setPreLoadingStatus,
          setUserKeys,
          setMedicKeys,
          destroySession,
          addToCart,
          removeFromCart,
          removeAllFromCart,
          resetCart,
          resetCurrentScheduling,
          resetSchedulingOnMount,
          updateCartStep,
          setCurrentAppointment,
          setPatientAmount,
          removeAddress,
          refreshAllAddresses,
          setCurrentAddress,
          resetAddresses,
          setCurrentTimeBlock,
          setForcingAddress,
          setSalesSource,
          setUTMId,
          setAlreadyShowedWelcomePopup,
        }}
      >
        {children}
      </ContextUpdate.Provider>
    </ContextValue.Provider>
  );
}
