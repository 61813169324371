exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-index-tsx": () => import("./../../../src/pages/app/index.tsx" /* webpackChunkName: "component---src-pages-app-index-tsx" */),
  "component---src-pages-app-patients-[id]-derivate-tsx": () => import("./../../../src/pages/app/patients/[id]/derivate.tsx" /* webpackChunkName: "component---src-pages-app-patients-[id]-derivate-tsx" */),
  "component---src-pages-app-patients-[id]-results-tsx": () => import("./../../../src/pages/app/patients/[id]/results.tsx" /* webpackChunkName: "component---src-pages-app-patients-[id]-results-tsx" */),
  "component---src-pages-app-patients-[id]-tsx": () => import("./../../../src/pages/app/patients/[id].tsx" /* webpackChunkName: "component---src-pages-app-patients-[id]-tsx" */),
  "component---src-pages-app-patients-index-tsx": () => import("./../../../src/pages/app/patients/index.tsx" /* webpackChunkName: "component---src-pages-app-patients-index-tsx" */),
  "component---src-pages-app-prescriptions-index-tsx": () => import("./../../../src/pages/app/prescriptions/index.tsx" /* webpackChunkName: "component---src-pages-app-prescriptions-index-tsx" */),
  "component---src-pages-app-profile-tsx": () => import("./../../../src/pages/app/profile.tsx" /* webpackChunkName: "component---src-pages-app-profile-tsx" */),
  "component---src-pages-app-telehealth-index-tsx": () => import("./../../../src/pages/app/telehealth/index.tsx" /* webpackChunkName: "component---src-pages-app-telehealth-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-provider-onboarding-tsx": () => import("./../../../src/pages/provider-onboarding.tsx" /* webpackChunkName: "component---src-pages-provider-onboarding-tsx" */)
}

