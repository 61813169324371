import { useEffect } from "react";

// import namespaced from "~/src/utils/debug";

/**
 * @example
 *
 * useOnClickOutside(containerRef, () => {
 *   debug("called from ModalAddresses");
 *   onClickOutside();
 * });
 */
function useOnClickOutside(ref: React.MutableRefObject<HTMLDivElement | null>, handler: (...args: any) => any) {
  useEffect(() => {
    const listener = (event: TouchEvent | MouseEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      const currentRef = ref?.current;
      if (!currentRef || currentRef.contains(event.target as Node)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

export default useOnClickOutside;
